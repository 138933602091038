<template lang="pug">
	article#expositores
		section#expositor
			figure.banner_evento
				img(:src="expositores.expositor_topo ? expositores.expositor_topo : require('@images/backgrounds/banner_expositores.png')").banner
			.wrapper
				router-link(:to="{name: 'evento'}").voltar
					img(:src="require('@images/icones/arrow.png')")
					span Voltar para o evento
				div(v-if="expositores.expositores.Expositores")
					h2 Parceiros
					p Conheça os parceiros confirmados:
					ul
						li(v-for="expositor, index in expositores.expositores.Expositores", v-if="expositor && expositor.thumb")
							a(:href="expositor.link" v-if="expositor.link", target="_blank")
								img(:src="expositor.thumb", :title="expositor.nome")
							img(:src="expositor.thumb", :title="expositor.nome", v-else)
				
				div(v-if="expositores.expositores.Apoiadores")
					h2 Apoiadores
					ul
						li(v-for="apoiador, index in expositores.expositores.Apoiadores", v-if="apoiador && apoiador.thumb")
							a(:href="apoiador.link" v-if="apoiador.link", target="_blank")
								img(:src="apoiador.thumb", :title="apoiador.nome")
							img(:src="apoiador.thumb", :title="apoiador.nome", v-else)
				
				div(v-if="expositores.expositores.Patrocinadores")
					h2 Patricinadores 
					ul
						li(v-for="patrocinador, index in expositores.expositores.Patrocinadores", v-if="patrocinador && patrocinador.thumb")
							a(:href="patrocinador.link" v-if="patrocinador.link", target="_blank")
								img(:src="patrocinador.thumb", :title="patrocinador.nome")
							img(:src="patrocinador.thumb", :title="patrocinador.nome", v-else)

				router-link(:to="{name: 'eventos'}").eventos Ir para eventos
		section#sejaUmExpositor
			.wrapper
				div.download(v-if="expositores.manual_expositor")
					h3 Seja um Parceiro
					p Saiba como se tornar um paceiro ou patrocinador do evento, acesse o manual do parceiro.
					a(:href="expositores.manual_expositor.link", target="_blank") Fazer download
					span {{ expositores.manual_expositor.nome }}
				div.atendimento
					h3 Atendimento ao Parceiro
					//- p Lorem ipsum dolor sit amet, consectetur dipisicing elit, sed do eiusmod tempor ut aliquip ex ea commodo consequat. irure dolor in reprehenderit in voluptate velit esse cillum.
					div.contato(v-for="contato in expositores.organizadores", v-if="expositores.organizadores")
						span Email 
						a(:href="'mailto:' + contato.email") {{ contato.email }}
						span Telefone 
						a(:href="'tel:' + contato.telefone1").inline {{ contato.telefone1 }} 
						a(:href="'tel:' + contato.telefone2", v-if="contato.telefone2").inline &nbsp;| {{ contato.telefone2 }}
		section#mapaEvento
			.wrapper
				h3 Veja o mapa do evento:
				img(:src="expositores.mapa_evento")
</template>

<script>
export default {
	data() {
		return{
			expositores: {}
		}
	},
	beforeRouteEnter (to, from, next) {
		next(vm => {
			vm.$axios
				.get(`api/eventos/${to.params.slug}/expositores`)
				.then(response => {
					vm.expositores = response.data;
				})
				.catch(() => {
					vm.$router.replace({ name: '404' })
				})
		})
	},
}
</script>

<style lang="stylus" scoped src="./Expositores.styl"></style>